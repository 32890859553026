import { AsyncPipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RowaActivityIndicatorModule } from '@rowa/ui';
import { BehaviorSubject, Observable, lastValueFrom, of, tap } from 'rxjs';
import { DialogRef } from 'src/app/core/components/dialog/dialog-ref';
import { LocalStorageService } from 'src/app/core/services/local-storage.service';
import { SwaggerService } from 'src/app/core/services/swagger.service';
import {
	GetOrderDto,
	OrderDetailsDto,
	OutputLocation,
	PickupOutputState,
} from 'src/app/swagger/model/models';
import { ConfirmDialogComponent } from '../../../../core/components/dialog/confirm-dialog/confirm-dialog.component';
import { ConnectionNotificationComponent } from '../connection-notification/connection-notification.component';
import { StorerxDialogComponent } from '../storerx-dialog/storerx-dialog.component';
import { VmaxDialogComponent } from '../vmax-dialog/vmax-dialog.component';
import { MODE, OrderDialogService } from './../order-dialog.service';
import { ButtonComponent } from 'src/app/core/components/button.component';
import { Router } from '@angular/router';

@Component({
	selector: 'app-dispense-order-dialog',
	templateUrl: './dispense-order-dialog.component.html',
	styles: [],
	standalone: true,
	imports: [
		ConfirmDialogComponent,
		ConnectionNotificationComponent,
		VmaxDialogComponent,
		StorerxDialogComponent,
		AsyncPipe,
		TranslateModule,
		RowaActivityIndicatorModule,
		ButtonComponent,
	],
})
export class DispenseOrderDialogComponent implements OnInit {
	@Input({ required: true }) overviewOrder: GetOrderDto;
	order: OrderDetailsDto;
	public selectedOutputLocation: OutputLocation = undefined;
	public isAdapterConnected$: Observable<boolean>;
	mode$: Observable<MODE>;
	isDespensable$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	getOutputLocations$: Observable<OutputLocation[]>;
	vmaxStored: number = 0;

	constructor(
		private localStorage: LocalStorageService,
		private dialogRef: DialogRef,
		private dialogService: OrderDialogService,
		private swagger: SwaggerService,
		private router: Router,
	) {
		this.getOutputLocations$ = this.getOutputLocations();
		this.isAdapterConnected$ =
			this.dialogService.isAdapterConnected$.asObservable();
		this.mode$ = this.dialogService.mode$.asObservable();
	}

	async ngOnInit() {
		this.order = await lastValueFrom(
			this.swagger.OrderServiceAPI.apiOrderGetStoragecodeGet(
				this.overviewOrder.storageCode,
			),
		);

		this.dialogService.get_dialog_mode(this.order);
		this.dialogService.is_adapter_connected();
		this.isDespensable$.next(
			this.order.stockInputs?.some((x) => x.dispenseDate == null) ||
				this.order.storedQuantity > 0 ||
				this.order.recurrentOrderArticles.some(
					(x) =>
						x.recurrentOrderArticleIterations.filter(
							(a) => a.outputDate == null,
						).length > 0,
				),
		);

		this.vmaxStored = this.order.articles.filter(
			(x) => x.state === PickupOutputState.None,
		).length;
	}

	public dispenseOrder() {
		if (this.selectedOutputLocation) {
			this.localStorage.setItem(
				'localDispenseOutput',
				this.selectedOutputLocation,
			);
		}
		this.dialogRef.confirm({
			outputId: this.selectedOutputLocation?.outputId,
			order: this.order,
			mode: this.dialogService.mode$.getValue(),
		});
	}

	close() {
		this.dialogRef.close();
	}

	gotoOutput() {
		this.close();
		this.router.navigate(['workstationsettings']);
	}

	getOutputLocations(): Observable<Array<OutputLocation>> {
		return this.dialogService.get_output_locations().pipe(
			tap((data: Array<OutputLocation>) => {
				this.selectedOutputLocation =
					this.dialogService.get_stored_outputlocation(
						'localDispenseOutput',
						data,
					);
			}),
		);
	}
}
